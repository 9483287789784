import axios from "axios";

export async function getProductFilterData() {
  try {
    const res = await axios.get(`product/filterData`);
    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

export async function trackProduct(productId) {
  try {
    const res = await axios.get(`reports/trackProduct/${productId}`);
    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

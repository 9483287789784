<template>
  <div>
    <el-row :gutter="20" class="mb-5">
      <el-col :span="12">
        <el-select
          v-model="filters.productCodes.selectedId"
          filterable
          clearable
          placeholder="Select a product code"
          @change="getProductTracking()"
        >
          <el-option v-for="item in filters.productCodes.items" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="{ background: '#f1f1f5', color: 'black', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            :data="props.row.shipped_details"
            style="width: 100%"
            :header-cell-style="{ color: 'black', textAlign: 'center', 'font-size': '11px' }"
            :cell-style="{ padding: '2', height: '20px', textAlign: 'center' }"
          >
            <el-table-column prop="ship_id" label="Shipment ID" />
            <el-table-column prop="ship_no" label="Air/Ship No" />
            <el-table-column prop="shipped_qty" label="Shpd.Qty" />
            <el-table-column prop="excess_qty" label="Ex.Qty" />
            <el-table-column label="Status">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status == 'DELIVERED'">Delivered</el-tag>
                <el-tag type="warning" v-else-if="scope.row.status == 'ON_TRANSIT'">On Transit</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="shipped_date" label="Shipped on" />
            <el-table-column prop="expected_delivery" label="Expected on" />
            <el-table-column prop="delivered_date" label="Delivered on" />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="Date" align="center" width="150">
        <template slot-scope="scope">
          {{ formatDate(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column label="Order ID" prop="orderId" width="100" />
      <el-table-column label="Branch" prop="name" />
      <el-table-column label="Order Qty" prop="quantity" width="150" />
      <el-table-column label="Status">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.status == 'PENDING'">Pending</el-tag>
          <el-tag type="warning" v-else-if="scope.row.status == 'CONFIRMED'">Confirmed</el-tag>
          <el-tag v-else-if="scope.row.status == 'READY_TO_SHIP'">Ready to Ship</el-tag>
          <el-tag v-else-if="scope.row.status == 'PARTIALLY_SHIPPED'">Partially Shipped</el-tag>
          <el-tag v-else-if="scope.row.status == 'SHIPPED'">Shipped</el-tag>
          <el-tag type="success" v-else-if="scope.row.status == 'PARTIALLY_DELIVERED'">Partially Delivered</el-tag>
          <el-tag type="success" v-else-if="scope.row.status == 'DELIVERED'">Delivered</el-tag>
          <el-tag type="danger" v-else-if="scope.row.status == 'REJECTED'">Rejected</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { DateService } from "../../../common/DateService";
import { getProductFilterData, trackProduct } from "./lib";

export default {
  name: "TrackProduct",
  data() {
    return {
      tableData: [],
      filters: {
        productCodes: {
          items: [],
          selectedId: null,
        },
      },
      value: "",
    };
  },
  methods: {
    formatDate: DateService.formatDate,
    async initialize() {
      await this.$store.dispatch("setLoading", true);
      const filterData = await getProductFilterData();

      this.filters.productCodes.items = filterData
        .map((item) => ({ label: item.code, value: item.id }))
        .sort((a, b) => a.label.localeCompare(b.label));

      await this.$store.dispatch("setLoading", false);
    },
    async getProductTracking() {
      await this.$store.dispatch("setLoading", true);
      this.tableData = await trackProduct(this.filters.productCodes.selectedId);
      await this.$store.dispatch("setLoading", false);
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>
